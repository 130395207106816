/**
 * Created by leo108 on 2018/5/25.
 */

import Echo from 'laravel-echo'
import get from 'lodash/get'
import BrowserNotificationManager from '../common/browser-notification-manager'
import { chrome_extension_installed } from '../utils'
import Pusher from 'pusher-js'
import { bus } from '../common/setup-app/event-bus.js'
import constants from '@/generated/constants.js'

window.Pusher = Pusher

class DubbPusher {
  constructor () {
    this.listening = false
  }

  listen (apiMode) {
    if (this.listening || Spark.disablePusher) {
      return
    }
    this.listening = true
    this.initPusher(apiMode)

    if (!Spark.hideNotification && this.isSelfDomain()) {
      BrowserNotificationManager.init()
    }
  }

  initPusher (apiMode) {
    const options = {
      broadcaster: 'reverb',
      key: import.meta.env.VITE_REVERB_APP_KEY,
      encrypted: true,
      cluster: 'mt1', // pusher-js > 8.0 requires cluster option, but it will be ignored when wsHost is set
      wsHost: import.meta.env.VITE_REVERB_HOST,
      wsPath: '/ws',
      disableStats: true,
      withoutInterceptors: true,
      enabledTransports: ['ws']
    }

    if (apiMode) {
      options.channelAuthorization = {
        endpoint: '/broadcasting/auth',
        headers: {
          Authorization: `ApiKey ${Spark.state.user.web_api_key}`
        }
      }
    }

    window.echo = new Echo(options)
    window.echo.registerAxiosRequestInterceptor()

    window.echo.channel('public-channel')
      .listen('BroadcastAnnouncement', (e) => {
        const n = new Notification(e.title, {
          body: e.truncated_text,
          icon: 'https://do.dubbcdn.com/img/favicon.ico',
          tag: 'announcement_' + e.id
        })
        n.onclick = () => {
          n.close()
          window.open(e.url)
        }
      })

    window.echo.private(`private-channel.${Spark.state.user.id}`)
      .notification(e => {
        bus.$emit(`private-channel.${e.channel}`, e)
        if (e.channel === 'video-tracked') {
          this.handleVideoTracked(e)
        } else if (e.channel === 'new-chat-message') {
          this.handleNewChatMessage(e)
        }
      })
  }

  handleVideoTracked (e) {
    const track = e.track
    if (!track) {
      return
    }
    if (chrome_extension_installed() || !BrowserNotificationManager.hasNotificationPermission) {
      return
    }

    const n = new Notification('New activity on your video', {
      body: track.full_description,
      icon: constants.GLOBAL.DUBB_ICON_URL,
      tag: 'track_' + track.id
    })
    n.onclick = () => {
      n.close()
      window.open(track.url ? track.url : track.video.url)
    }
  }

  handleNewChatMessage (e) {
    if (!e.conversation || !e.message) {
      return
    }
    if (!BrowserNotificationManager.hasNotificationPermission) {
      return
    }
    if (e.message.sender_id === e.conversation.user_id) {
      return
    }

    const shouldSendNotification = get(
      Spark.state.user.k_v_settings,
      [
        constants.App_Models_UserSetting.DESKTOP_NOTIFICATION,
        constants.App_Models_UserSetting.NOTIFICATION_CHAT
      ]
    )
    if (!shouldSendNotification) {
      return
    }

    const n = new Notification(`New message from ${e.conversation.friend.name}`, {
      body: e.message.message,
      icon: 'https://do.dubbcdn.com/img/favicon.ico',
      tag: 'message_' + e.message.id
    })
    n.onclick = () => {
      n.close()
    }
  }

  isSelfDomain () {
    if (!Spark.state.user) {
      return false
    }
    if (location.hostname === import.meta.env.VITE_APP_DOMAIN) {
      return true
    }
    if (!Spark.state.currentTeam) {
      return false
    }
    const re = new RegExp('([^.]+)\\.' + import.meta.env.VITE_APP_DOMAIN.replace('.', '\\.'), 'i')
    const ret = re.exec(location.hostname)
    if (!ret) {
      return false
    }

    return ret[1] === Spark.state.currentTeam.slug
  }
}

window.dubbPusher = new DubbPusher()

function listenDubbPusher (apiMode = false) {
  if (Spark.state.user && import.meta.env.VITE_DISABLE_NOTIFICATION !== 'true') {
    window.dubbPusher.listen(apiMode)
  }
}

listenDubbPusher()

bus.$on('login-user', (options) => {
  // `options` can be `undefined` or an object
  const shouldListenDubbPusher = options?.shouldListenDubbPusher ?? true

  if (shouldListenDubbPusher) {
    listenDubbPusher(options?.apiMode ?? false)
  }
})
