import Cookies from 'js-cookie'
import URI from 'urijs'
import constants from '@/generated/constants.js'

const affiliateParams = window.affiliateParams || URI.parseQuery((new URI(location.href)).search())

if (affiliateParams && affiliateParams.ref) {
  if (affiliateParams.ref_vendor === constants.GLOBAL.AFFILIATE_VENDOR_PARTNER_STACK) {
    Cookies.set('growSumoPartnerKey', affiliateParams.ref, { expires: 60, path: '/', domain: '.' + import.meta.env.VITE_APP_DOMAIN })
  } else if (affiliateParams.ref_vendor === constants.GLOBAL.AFFILIATE_VENDOR_FIRST_PROMOTER) {
    const cookieName = affiliateParams.key_type === constants.GLOBAL.FIRST_PROMOTER_KEY_TYPE_TID ? constants.GLOBAL.PARTNER_KEY_COOKIE_FIRST_PROMOTER_TID : constants.GLOBAL.PARTNER_KEY_COOKIE_FIRST_PROMOTER_REF
    Cookies.set(cookieName, affiliateParams.ref, { expires: 60, path: '/', domain: '.' + import.meta.env.VITE_APP_DOMAIN })
  }
}
